import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faBuilding } from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/personaldetails.module.scss"
import { ErrorMessage } from "formik"

const addressTypes = [
  {
    value: "Home",
    icon: faHome,
  },
  {
    value: "Office",
    icon: faBuilding,
  },
]

const AddressTypeChoices = ({ addressTypeName, values, setFieldValue }) => (
  <div className="mb-1">
    <label className="has-text-weight-bold">
      Address Type <span className="has-text-danger">*</span>
    </label>
    <div
      className={classNames("tabs is-toggle is-toggle-rounded", styles["tabs"])}
    >
      <ul>
        {addressTypes.map(addressType => (
          <li
            onClick={() => {
              setFieldValue(addressTypeName || "addressType", addressType.value)
            }}
            className={`${values?.addressType === addressType.value &&
              "is-active"}`}
          >
            <a>
              <span className="icon is-small">
                <FontAwesomeIcon icon={addressType.icon} />
              </span>
              <span>{addressType.value}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
    <ErrorMessage name={addressTypeName}>
      {error => <div className="has-text-danger is-size-7">{error}</div>}
    </ErrorMessage>
  </div>
)

export default AddressTypeChoices
