import React, { Fragment, useState, useEffect } from "react"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faBuilding } from "@fortawesome/free-solid-svg-icons"
import address from "./utils/philippineLocations.json"
import AddressTypeChoices from "./Address/AddressTypeChoices.js"

const INPUT_STREET_PLACEHOLDER = "Unit/House No., Building, Street, Barangay"
const SELECT_CITY_PLACEHOLDER = "Select city..."

const Address = ({
  isRequired,
  fieldNames = {},
  values = {},
  isNationwide,
  helper,
  setFieldValue,
}) => {
  const { addressType, streetAddress, province, city } = fieldNames
  const [listOfProvince, setProvinces] = useState([])
  const [listOfCity, setListOfCity] = useState([])

  useEffect(() => {
    initializeProvinces()
    const { Cities } = address.filter(loc => loc.Province === "Metro Manila")[0]
    setListOfCity(sortCities(Cities))
    //eslint-disable-next-line
  }, [])

  const initializeProvinces = () => {
    let options = address.map(remapProvinces)
    const metroManila = options.filter(({ value }) => value === "Metro Manila")
    if (isNationwide) setProvinces(options)
    else setProvinces(metroManila)
  }

  const remapProvinces = ({ Province }) => ({
    value: Province,
    label: Province,
  })

  const sortCities = cities => {
    const sortedCities = cities
      .map(({ City }) => ({
        value: City,
        label: City,
      }))
      .sort((a, b) => {
        return a.value > b.value ? 1 : -1
      })

    return sortedCities
  }
  const handleOnChange = ({ value }, setFieldValue) => {
    const { Cities } = address.filter(loc => loc.Province === value)[0]
    setListOfCity(sortCities(Cities))
    setFieldValue(city || "city", "")
  }

  return (
    <Fragment>
      <AddressTypeChoices
        addressTypeName={addressType}
        values={values}
        setFieldValue={setFieldValue}
      />
      <FormInput
        name={streetAddress || "streetAddress"}
        label="Street Address"
        helper={helper}
        placeholder={INPUT_STREET_PLACEHOLDER}
        isRequired={isRequired}
      />
      <FormSelect
        name={province || "province"}
        label="Province"
        isRequired={isRequired}
        value={values.province}
        options={listOfProvince}
        onChange={handleOnChange}
      />
      <FormSelect
        isClearable={true}
        name={city || "city"}
        label="City"
        placeholder={SELECT_CITY_PLACEHOLDER}
        value={values.city}
        isRequired={isRequired}
        options={listOfCity}
      />
    </Fragment>
  )
}

export default Address
