import React from "react"
import Layout from "../../components/Layout/Layout"
import DeliveryDetailsForm from "../../components/Epharmacy/DeliveryDetailsForm"

export default () => (
  <Layout
    title="Input Details"
    subtitle="Please answer the following questions accurately. Your information will be used as a basis for your delivery."
    seoTitle="Delivery Details"
    process="epharmacy"
  >
    <DeliveryDetailsForm
      backRoute="/epharmacy/upload"
      nextRoute="/epharmacy/summary"
    />
  </Layout>
)
