import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import PropTypes from "prop-types"
import { isEqual } from "lodash"

import Container from "../Layout/Container"
import Section from "../Elements/Section"
import Address from "../PersonalDetails/Address"
import FormTextArea from "../Elements/Form/FormTextarea"
import PersonalDetails from "../PersonalDetails/PersonalDetails"
import ActionButtons from "../Elements/ActionButtons"

import {
  deliveryDetailsValidationSchema,
  deliveryDetailsWithBeneficiaryValidationSchema,
  // deliveryDetailsInitialValues,
} from "./utils/formData"
import { AppContext } from "../../context/AppContext"

/**
 * @param {string} backRoute string
 * @param {string} nextRoute string
 */

const DeliveryDetailsForm = ({ backRoute, nextRoute }) => {
  const { dispatch, state } = useContext(AppContext)
  const addressFieldNames = {
    addressType: `deliveryAddress.addressType`,
    streetAddress: `deliveryAddress.streetAddress`,
    city: `deliveryAddress.city`,
    province: `deliveryAddress.province`,
  }
  const orderingForBeneficiary = state.orderingFor === "My Immediate Family"
  const formValidationSchema = orderingForBeneficiary
    ? deliveryDetailsWithBeneficiaryValidationSchema
    : deliveryDetailsValidationSchema

  return (
    <Container isCentered desktop={6}>
      <Formik
        initialValues={state.epharmacy}
        onSubmit={async values => {
          await dispatch({ type: "SAVE_DELIVERY_DETAILS", payload: values })
          navigate(nextRoute)
        }}
        validationSchema={formValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Section title="Employee Details">
              <PersonalDetails
                values={values}
                orderingForBeneficiary={orderingForBeneficiary}
              />
            </Section>
            <Section title="Delivery Address">
              <Address
                fieldNames={addressFieldNames}
                values={values.deliveryAddress}
                isNationwide
                isRequired
                setFieldValue={setFieldValue}
              />
              <FormTextArea
                name="deliveryAddress.notes"
                label="Delivery Notes"
                placeholder="Green gate; preferred time of delivery: 4PM"
              />
            </Section>
            <ActionButtons
              submit={{ label: "Next" }}
              back={{ label: "Back", link: backRoute }}
            />
          </Form>
        )}
      </Formik>
    </Container>
  )
}

DeliveryDetailsForm.propTypes = {
  backRoute: PropTypes.string,
}

export default DeliveryDetailsForm
