import * as Yup from "yup"

export const deliveryDetailsValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .required("This field is required"),
  employeeNumber: Yup.string()
    .trim()
    .required("This field is required"),
  mobileNumber: Yup.string()
    .trim()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .trim()
    .email("Please input a valid email")
    .required("This field is required"),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string()
      .trim()
      .required("This field is required"),
    city: Yup.string()
      .trim()
      .required("This field is required"),
    province: Yup.string()
      .trim()
      .required("This field is required"),
    addressType: Yup.string()
      .trim()
      .required("This field is required"),
  }),
  civilStatus: Yup.string()
    .trim()
    .required("This field is required"),
})

export const deliveryDetailsWithBeneficiaryValidationSchema = Yup.object().shape(
  {
    fullName: Yup.string()
      .trim()
      .required("This field is required"),
    employeeNumber: Yup.string()
      .trim()
      .required("This field is required"),
    mobileNumber: Yup.string()
      .trim()
      .min(11, "Please input a valid mobile number in this format: 09991234567")
      .max(11, "Please input a valid mobile number in this format: 09991234567")
      .matches(
        /^[0-9]*$/,
        "Please input a valid mobile number in this format: 09991234567"
      )
      .required("This field is required"),
    emailAddress: Yup.string()
      .trim()
      .email("Please input a valid email")
      .required("This field is required"),
    deliveryAddress: Yup.object().shape({
      streetAddress: Yup.string()
        .trim()
        .required("This field is required"),
      city: Yup.string()
        .trim()
        .required("This field is required"),
      province: Yup.string()
        .trim()
        .required("This field is required"),
      addressType: Yup.string()
        .trim()
        .required("This field is required"),
    }),
    civilStatus: Yup.string()
      .trim()
      .required("This field is required"),
    beneficiaryName: Yup.string()
      .trim()
      .required("This field is required"),
    beneficiaryRelationship: Yup.string()
      .trim()
      .required("This field is required"),
  }
)

export const deliveryDetailsInitialValues = {
  fullName: "",
  employeeNumber: "",
  mobileNumber: "",
  emailAddress: "",
  deliveryAddress: {
    streetAddress: "",
    city: "",
    province: "",
    notes: "",
  },
}
