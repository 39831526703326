import React, { Fragment } from "react"
import FormInput from "../Elements/Form/FormInput"
import FormSelect from "../Elements/Form/FormSelect"
import civilStatus from "./utils/civilStatus.json"

const civilStatusOptions = civilStatus.map(option => ({
  label: option,
  value: option,
}))

const PersonalDetails = ({ values = {} }) => (
  <Fragment>
    <FormInput
      name="firstName"
      label="First Name"
      placeholder="Juan"
      isRequired
    />
    <FormInput
      name="lastName"
      label="Last Name"
      placeholder="Dela Cruz"
      isRequired
    />
    <FormInput
      name="employeeNumber"
      label="Employee ID"
      placeholder="JDC001"
      maxLength={6}
      isRequired
      isUpperCase
    />
    <FormInput
      name="mobileNumber"
      label="Mobile Number"
      placeholder="09123456789"
      isRequired
      maxLength={11}
    />
    <FormInput
      name="emailAddress"
      label="Work Email"
      placeholder="jdelacruz@maersk.com"
      isRequired
    />
    <FormInput
      name="personalEmail"
      label="Personal Email"
      placeholder="jdelacruz@mail.com"
      isRequired
    />
    <FormInput
      name="designation"
      label="Designation"
      placeholder="Manager"
      isRequired
    />
    <FormSelect
      name="civilStatus"
      label="Civil Status"
      placeholder="Select civil status..."
      isRequired
      options={civilStatusOptions}
      value={values.civilStatus}
    />
  </Fragment>
)

export default PersonalDetails
